/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 */

import _ from '@sv/underscore';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import {
  inputConfig,
  isMaliciousFileType,
  i18nTimeline,
  handleWarn,
  verifyFileUpload,
  truncateFileName,
} from '../utils';
import CommentModel from '../model/Comment';
import CommentView from './Comment';
import { getModelObjectUri, getTemplate } from '../../../util/portletUtil';
import {
  Ajax as ajax,
  Events as events,
  KeyUtil as keyUtil,
  ErrorUtil as errorUtil,
} from '@sv/util';

const KEY = keyUtil.KEY;

const Comments = Backbone.View.extend({
  initialize: function () {
    this.$list = this.$el.find('ol');
    this.bindCollectionEvents();
    this.bindPlugins();

    this.commentTemplate = getTemplate(
      this.$el.closest(
        '.sv-timeline-portlet,.sv-tagtimeline-portlet,.sv-timelinesearch-portlet,.sv-timelineentry-portlet'
      ),
      'comment'
    );

    if (this.collection.length > 0) {
      this.render();
    }
  },

  bindCollectionEvents: function () {
    this.listenTo(this.collection, 'add', this.addNewComment);
    this.listenTo(this.collection, 'updateCommentArea', this.updateCommentArea);
  },

  updateCommentArea: function (show) {
    const $commentArea = this.$el.find('[data-fn-comment-area]');

    $commentArea[show ? 'removeClass' : 'addClass']('sv-hidden');
  },

  addNewComment: function (comment) {
    this.appendOne(comment);

    events.trigger(events.types.updateRelativeDates, this.$el);
    events.trigger(events.types.updateLikables, this.$el);
  },

  bindPlugins: function () {
    const commentPrefix = this.collection.isSubComment ? 'sub' : '';
    const $dropzone = this.$el.find(`[data-fn-${commentPrefix}comment-form]`);

    this.uploadedCommentFiles = [];
    this.getTextField().triggeredInput(inputConfig).elastic();

    this.$el
      .find(`[data-fn-${commentPrefix}comment-form] input.sv-file-input`)
      .fileupload({
        dataType: 'json',
        url: getModelObjectUri(this.options.userIdentity, 'timelineFileUpload'),
        replaceFileInput: true,
        formAcceptCharset: 'utf-8',
        dropZone: $dropzone,

        submit: (e, data) => {
          const commentFile = data.files[0];
          let commentFileSize = commentFile.size;

          if (
            this.uploadedCommentFiles.filter(
              (uploadedFiles) => uploadedFiles.originalName === commentFile.name
            ).length
          ) {
            return false;
          }

          if (isMaliciousFileType(commentFile.name)) {
            events.trigger(events.types.notifyUser, {
              type: 'error',
              heading: i18nTimeline('maliciousFileTypeTitle'),
              message: i18nTimeline('maliciousFileTypeMessage'),
            });
            return false;
          }

          // Make sure that the file that is about to get uploaded is accepted
          if (
            !verifyFileUpload(
              commentFile.name,
              commentFileSize,
              this.options.userIdentity,
              this.options.portletId
            )
          ) {
            return false;
          }

          this.$el
            .find(`[data-fn-${commentPrefix}comment-file-loading]`)
            .show();
          this.disableTextField();
        },

        done: (e, data) => {
          const submittedFileName = data.result.result.originalName;
          const truncatedFileName =
            submittedFileName.length > 30
              ? truncateFileName(submittedFileName)
              : submittedFileName;

          this.uploadedCommentFiles.push(data.result.result);
          this.$el.find(`[data-${commentPrefix}comment-files-added]`).append(
            `<div class="sv-${commentPrefix}comment-file-added env-d--flex" data-fn-${commentPrefix}comment-file-added data-fileId="${
              data.result.result.id
            }">
            <a class="close sv-remove-attachment env-m-right--xx-small" data-fn-destroy-${commentPrefix}comment-file title="${i18nTimeline(
              'removeAttachment'
            )}" href="#">&times;</a>
            <span data-fn-filename>${_.escape(truncatedFileName)}</span>
            </div>`
          );

          this.$el.find(`[data-${commentPrefix}comment-files-added]`).show();
          this.$el
            .find(`[data-fn-${commentPrefix}comment-file-loading]`)
            .hide();

          this.$el
            .find(`.sv-amount-of-${commentPrefix}comment-files`)
            .show()
            .text(` (${this.uploadedCommentFiles.length})`);

          this.enableTextField();
          this.getTextField().trigger('focus');
        },
      });
  },

  render: function () {
    this.clearList();
    this.collection.each(function (item) {
      this.appendOne(item);
    }, this);

    events.trigger(events.types.updateRelativeDates, this.$el);
    events.trigger(events.types.updateLikables, this.$el);
  },

  appendOne: function (comment) {
    var view = new CommentView({
      model: comment,
      template: this.commentTemplate,
      portletId: this.options.portletId,
      allowEmojis: this.options.allowEmojis,
      userIdentity: this.options.userIdentity,
      maxCharacterCount: this.options.maxCharacterCount,
      requireActiveGroup: this.options.requireActiveGroup,
      profilePageURL: this.options.profilePageURL,
      tagResultPageURL: this.options.tagResultPageURL,
      entryId: this.collection.id,
      getEmojiPicker: this.options.getEmojiPicker,
    });

    var commentEl = view.render().$el;
    var commentContentObj = commentEl.find('.sv-comment-content');
    var truncate = commentContentObj.find('.sv-truncate-more'),
      split;

    if (truncate.length > 0) {
      this.addShowMoreLink(truncate);
      split = true;
    }

    this.$list.append(commentEl);

    if (split) {
      this.bindShowMoreEvents(commentEl);
    }
  },

  bindShowMoreEvents: function (obj) {
    var options = {
      moreText: i18nTimeline('showMore'),
      lessText: i18nTimeline('showLess'),
    };
    var moreLink = $('.sv-truncate-more-link', obj),
      moreContent = $('.sv-truncate-more', obj),
      ellipsis = $('.sv-truncate-ellipsis', obj);

    moreLink.on('click', function (e) {
      e.preventDefault();
      if (moreLink.text() === options.moreText) {
        moreContent.show();
        moreLink.text(options.lessText);
        ellipsis.css('display', 'none');
      } else {
        moreContent.hide();
        moreLink.text(options.moreText);
        ellipsis.css('display', 'inline');
      }
    });
  },

  addShowMoreLink: function (obj) {
    $('<span class="sv-truncate-ellipsis">...</span>').insertBefore(obj);
    $(
      '<div>' +
        '<a href="#" class="sv-truncate-more-link">' +
        i18nTimeline('showMore') +
        '</a>' +
        '</div>'
    ).insertAfter(obj);
    obj.css('display', 'none');
  },

  appendAll: function (timeline) {
    timeline.each(this.appendOne, this);
  },

  clearList: function () {
    this.$list.empty();
  },

  getLoader: function () {
    if (!this.$loader) {
      this.$loader = $(
        '<img src="/sitevision/util/images/loading_16_grey.gif" />'
      )
        .css('vertical-align', 'middle')
        .appendTo(this.$el.find('[data-fn-comment-input]').parent());
    }

    return this.$loader;
  },

  countdownCharactersLeft: function (e) {
    var $target = $(e.currentTarget),
      text = $target.val(),
      size = this.options.maxCharacterCount - text.length,
      sizeField = this.getSizeField();

    if (size < 0) {
      sizeField.addClass('sv-character-limit-exceeded');
    } else {
      sizeField.removeClass('sv-character-limit-exceeded');
    }
    sizeField.text(size);

    if (
      ((text.trim() !== '' && size >= 0) ||
        this.uploadedCommentFiles.length > 0) &&
      !this.activeConnection
    ) {
      this.getSubmitButton().removeClass('disabled').prop('disabled', false);
    } else {
      this.getSubmitButton().addClass('disabled').attr('disabled', 'disabled');
    }

    return false;
  },

  getSizeField: function () {
    if (!this.$sizeField) {
      var selector = this.collection.isSubComment
        ? '[data-fn-subcomment-input-size]'
        : '[data-fn-comment-input-size]';

      this.$sizeField = this.$el.find(selector);
    }
    return this.$sizeField;
  },

  getSubmitButton: function () {
    if (!this.$submitButton) {
      var selector = this.collection.isSubComment
        ? '[data-fn-subcomment-submit]'
        : '[data-fn-comment-submit]';

      this.$submitButton = this.$el.find(selector);
    }
    return this.$submitButton;
  },

  getTextField: function () {
    if (!this.$textField) {
      var selector = this.collection.isSubComment
        ? '[data-fn-subcomment-input]'
        : '[data-fn-comment-input]';

      this.$textField = this.$el.find(selector);
    }
    return this.$textField;
  },

  disableTextField: function () {
    this.getSubmitButton().addClass('disabled').attr('disabled', 'disabled');
  },

  enableTextField: function () {
    const textField = this.getTextField();
    if (textField.val() !== '' || this.uploadedCommentFiles) {
      this.getSubmitButton().removeClass('disabled').prop('disabled', false);
    }
  },

  getSubmitControls: function () {
    if (!this.$submitControls) {
      var selector = this.collection.isSubComment
        ? '.sv-submit-subcontrols'
        : '.sv-submit-controls';

      this.$submitControls = this.$el.find(selector);
    }
    return this.$submitControls;
  },

  events: function () {
    if (this.collection.isSubComment) {
      return {
        'focus [data-fn-subcomment-input]': 'expandTextfield',
        'keyup [data-fn-subcomment-input]': 'countdownCharactersLeft',
        'click [data-fn-subcomment-submit]': 'postComment',
        'click [data-subcomment-emoji-button]': 'handleEmoji',
        'click [data-fn-destroy-subcomment-file]': 'destroyFile',
        'keydown [data-subcomment-emoji-button]': 'checkEmojiKeyDown',
      };
    }

    return {
      'focus [data-fn-comment-input]': 'expandTextfield',
      'keyup [data-fn-comment-input]': 'countdownCharactersLeft',
      'click [data-fn-comment-submit]': 'postComment',
      'click [data-fn-destroy-comment-file]': 'destroyFile',
      'click [data-comment-emoji-button]': 'handleEmoji',
      'keydown [data-comment-emoji-button]': 'checkEmojiKeyDown',
    };
  },

  handleEmoji: function () {
    var picker = this.options.getEmojiPicker('right-start'),
      $inputField = this.getTextField();

    picker.on('emoji', function (emoji) {
      var field = $inputField[0],
        selectionStart = field.selectionStart,
        selectionEnd = field.selectionEnd,
        inputValue = $inputField.val(),
        startString = inputValue.substring(0, selectionStart),
        endString = inputValue.substring(selectionEnd, inputValue.length);

      $inputField.val(startString + emoji + endString);
      $inputField.trigger('focus');
    });

    var $button = this.getEmojiButton();

    picker.pickerVisible ? picker.hidePicker() : picker.showPicker($button);
  },

  getEmojiButton: function () {
    if (!this.$emojiButton) {
      this.$emojiButton = this.$el.find(
        this.collection.isSubComment
          ? '[data-subcomment-emoji-button]'
          : '[data-comment-emoji-button]'
      );
    }
    return this.$emojiButton;
  },

  destroyFile: function (e) {
    const fileElement = e.currentTarget.parentElement;
    const fileId = fileElement.dataset.fileid;
    const commentPrefix = this.collection.isSubComment ? 'sub' : '';

    ajax.doDelete({
      url:
        getModelObjectUri(this.options.userIdentity, 'timelineFileUpload') +
        '/' +
        fileId,
    });

    this.uploadedCommentFiles = this.uploadedCommentFiles.filter(function (
      file
    ) {
      return file.id != fileId;
    });

    if (this.uploadedCommentFiles.length) {
      this.$el
        .find(`.sv-amount-of-${commentPrefix}comment-files`)
        .show()
        .text(` (${this.uploadedCommentFiles.length})`);
    } else {
      this.$el.find(`.sv-amount-of-${commentPrefix}comment-files`).hide();

      if (this.getTextField().val()) {
        this.enableTextField();
      } else {
        this.disableTextField();
      }
    }

    $(fileElement).remove();
    return false;
  },

  checkKeyDown: function (e) {
    var key = keyUtil.getKeyCodeFromEvent(e);
    if (key === KEY.RETURN && e.ctrlKey) {
      this.postComment(e);
    }
  },

  checkEmojiKeyDown: function (e) {
    var key = keyUtil.getKeyCodeFromEvent(e);
    if (key === KEY.RETURN) {
      this.handleEmoji();
    }
  },

  expandTextfield: function (e) {
    $(e.currentTarget).addClass('sv-comment-input-expanded');
    this.getSubmitControls().show();
    this.countdownCharactersLeft(e);
    this.checkKeyDown(e);
    return false;
  },

  postComment: function (e) {
    var that = this,
      comment,
      commentLength,
      field,
      loaderTimeout;

    e.preventDefault(); // Make sure form isn't POSTed
    field = this.getTextField();
    commentLength = field.val().length;

    const fileList = [];
    if (this.uploadedCommentFiles) {
      this.uploadedCommentFiles.forEach(function (file) {
        fileList.push(file.id);
      });
    }

    if (
      (commentLength > 0 && commentLength <= this.options.maxCharacterCount) ||
      this.uploadedCommentFiles
    ) {
      this.activeConnection = true;
      comment = new CommentModel({
        comment: field.triggeredInput('getRichContent'),
        files: this.uploadedCommentFiles ? fileList.join(',') : undefined,
      });

      loaderTimeout = setTimeout(function () {
        that.getLoader().show();
      }, 400);

      this.collection.create(comment, {
        wait: true,
        success: function () {
          field.val('').css('height', '20px');
          that.activeConnection = false;
          that.getLoader().hide();
          clearTimeout(loaderTimeout);
          that.getSubmitControls().hide();
          that.uploadedCommentFiles = [];

          const commentPrefix = that.collection.isSubComment ? 'sub' : '';
          that.$el.find(`.sv-amount-of-${commentPrefix}comment-files`).hide();
          that.$el.find(`[data-${commentPrefix}comment-files-added]`).empty();
        },
        error: function (response) {
          that.activeConnection = false;
          that.getLoader().hide();
          clearTimeout(loaderTimeout);
          errorUtil.handleAjaxFailure(response);
        },
      });
    } else if (commentLength > this.options.maxCharacterCount) {
      handleWarn(
        i18nTimeline('commentToLongHeading'),
        i18nTimeline('commentToLongBody')
      );
    }
    return false;
  },
});

export default Comments;
