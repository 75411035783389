/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 */

 import $ from '@sv/jquery';

$('.sv-timelineimages-portlet').each(function() {
   import(/* webpackChunkName: "fancybox-plugins" */ './plugins').then(() => {
      var $portlet = $(this);

      $portlet.find('a.sv-timeline-image').fancybox({
         padding: 0,
         cyclic: true,
         transitionIn: 'fade',
         transitionOut: 'fade'
      });
   });
});
