/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 *
 * Handles file browsing
 * Works alongside with fileshareall.css and file_browse.vm
 */
import sv from '@sv/core';
import _ from '@sv/underscore';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import {
   getPortletResourceUri,
   getModelObjectUri,
   getTemplate
} from '../../util/portletUtil';
import {
   clearHashValue,
   hasHashValue,
   reloadPage,
   setHashValue
} from '../../util/windowLocationUtil';
import {
   Events as events,
   ObjectUtil as objectUtil,
   ErrorUtil as errorUtil,
   DialogUtil as dialogUtil,
   ClientUtil as clientUtil,
   i18n as _i18n,
 } from '@sv/util';

var searchTimer,
   handleError = function(message,heading) {
      events.trigger(events.types.notifyUser, {
         type: 'error',
         heading: heading ? heading : getCommonText('error'),
         message: message
      });
      return false;
   };

// Store the selected items
var setClipboardItems = function(anItems, aRepositoryId) {
   var key = 'sv-fileview-clipboard-items-' + aRepositoryId;

   if ('localStorage' in window && window.localStorage !== null) {
      window.localStorage.setItem(key, JSON.stringify(anItems));
   }
};

// Get the selected items
var getClipboardItems = function(aRepositoryId) {
   var key = 'sv-fileview-clipboard-items-' + aRepositoryId,
      items;

   if ('localStorage' in window && window.localStorage !== null) {
      items = window.localStorage.getItem(key);
   }

   if (items) {
      return JSON.parse(items);
   } else {
      return {};
   }
};

// Check if items are selected
var hasClipboardItems = function(aRepositoryId) {
   var items = getClipboardItems(aRepositoryId);
   return !$.isEmptyObject(items);
};

// Clear stored selected items
var clearClipboardItems = function(aRepositoryId) {
   setClipboardItems({}, aRepositoryId);
};

// Check if there are items selected that isn't present on the clipboard
var hasSelectedItemsNotOnClipboard = function(aTable) {
   return aTable.find('.sv-file-checkbox:checked').length > 0;
};

// Check if items has been selected
var hasSelectedItems = function(aTable) {
   return aTable.find('.sv-file-checkbox:checked').length > 0;
};

// Check if unselected items exists
var hasUnselectedItems = function(aTable) {
   return aTable.find('.sv-file-checkbox:not(:checked)').length > 0;
};

// Localized messages
var getText = function(aBundleKey, anArgs) {
   return _i18n.getText('portlet.social.fileview.browse', aBundleKey, anArgs);
};

// Localized messages shared with other portlets
var getCommonText = function(aBundleKey, anArgs) {
   return _i18n.getText('common', aBundleKey, anArgs);
};

// Clear search filter field
var clearSearchQuery = function(anInputField) {
   anInputField.val('');
};

// Clear tag selection
var clearTagFilterSelection = function(aTagList) {
   aTagList.find('li.active').removeClass('active');
};

// Get the id of a tag
var getTagId = function(aTag) {
   return aTag.find('a').attr('data-fn-file-tag');
};

// Get the text of a tag
var getTagName = function(aTag) {
   return aTag.find('a').html();
};

// Get a tag by id
var getTagById = function(aTagId, aTagList) {
   var tag;

   aTagList.find('li').each(function() {
      var tagCandidate = $(this);

      if (aTagId === getTagId(tagCandidate)) {
         tag = tagCandidate;
         return false; // Abort each loop
      }
   });

   return tag;
};

// Determine if a tag is selected (or not)
var isTagSelected = function(aTag) {
   return aTag.hasClass('active');
};

// Show an element
var showElement = function(anElement){
   if (anElement.hasClass('sv-hidden')) {
      anElement.removeClass('sv-hidden');
   }
};

// Hides an element
var hideElement = function(anElement){
   if (!anElement.hasClass('sv-hidden')) {
      anElement.addClass('sv-hidden');
   }
};

// Update the visibility status of an element
var updateElementVisibility = function(aVisible, anElement) {
   if (aVisible) {
      showElement(anElement);
   } else {
      hideElement(anElement);
   }
};

// Hide elements with a specific class
var hideElementsWithClass = function(aClass, aParentElement){
   aParentElement.find(aClass).each(function() {
      var element = $(this);
      hideElement(element);
   });
};

// Show elements with a specific class
var showElementsWithClass = function(aClass, aParentElement){
   aParentElement.find(aClass).each(function() {
      var element = $(this);
      showElement(element);
   });
};

// Update menu item visibility
var updateMenuAlternativeVisibility = function(aVisible, aMenuItemLink) {
   var menuItem = aMenuItemLink.closest('li');
   updateElementVisibility(aVisible, menuItem);
};

// Hide the breadcrumb items that contains the folder path
var hideNodeBreadcrumb = function(aBreadcrumbList) {
   hideElementsWithClass('.sv-fileview-breadcrumb-node', aBreadcrumbList);
};

// Show the breadcrumb items that contains the folder path
var showNodeBreadcrumb = function(aBreadcrumbList) {
   showElementsWithClass('.sv-fileview-breadcrumb-node', aBreadcrumbList);
};

// Hide the breadcrumb item that contains the text 'Search result'
var hideSearchResultBreadcrumb = function(aBreadcrumbList) {
   hideElementsWithClass('.sv-fileview-breadcrumb-search-result', aBreadcrumbList);
};

// Show the breadcrumb item that contains the text 'Search result'
var showSearchResultBreadcrumb = function(aBreadcrumbList) {
   showElementsWithClass('.sv-fileview-breadcrumb-search-result', aBreadcrumbList);
};

// Hide the breadcrumb item that contains the selected tag
var hideTagBreadcrumb = function(aBreadcrumbList) {
   hideElementsWithClass('.sv-fileview-breadcrumb-tag', aBreadcrumbList);
};

// Hide the breadcrumb item that contains the selected tag
var showTagBreadcrumb = function(aTag, aBreadcrumbList) {
   showElementsWithClass('.sv-fileview-breadcrumb-tag', aBreadcrumbList);

   var tagName = getTagName(aTag);
   aBreadcrumbList.find('.sv-fileview-breadcrumb-tag-name').html('#' + tagName);
};

// Mark a tag as selected
var setTagSelected = function(aTag) {
   return aTag.addClass('active');
};

// Get the tag the is currently selected
var getSelectedTag = function(aTagList) {
   var tag = aTagList.find('li.active:first');

   if (tag.length === 1) {
      return tag;
   } else {
      return;
   }
};

// Get the search query term (if available)
var getSearchQueryTerm = function(aSearchInputField) {
   var term = aSearchInputField.val();
   return term !== '' && term.length > 0 ? term : undefined;
};

// Update file list with fresh data from the server
var reloadFileListView = function(aFileListView) {
   aFileListView.collection.fetch({
      reset : true,
      wait: true,
      success: function() {
         aFileListView.render();
         updateBootstrapTooltips(aFileListView);
         updateMenuVisibility(aFileListView);
         updateVisibleClipboardItems(aFileListView);
      }
   });
};

// Update Twitter Bootstrap tooltips
var updateBootstrapTooltips = function(aFileListView) {
   $(aFileListView.getTable()).find('.sv-fileview-tooltip').each(function () {
      $(this).tooltip();
   });
};

// Update visible clipboard items
var updateVisibleClipboardItems = function(aFileListView) {
   var clipboardItems = getClipboardItems(aFileListView.getRepositoryId());

   $.each(clipboardItems, function(aKey) {
      $('input[data-id="' + aKey +'"]').prop('checked', true).closest('tr').addClass('sv-cut');
   });
};

// Show or hide menu items depending on the visible items and clipboard status
var updateMenuVisibility = function(aFileListView) {
   var table = aFileListView.getTable(),
         tagList = aFileListView.getTagList(),
         searchInputField = aFileListView.getSearchInputField(),
         repositoryId = aFileListView.getRepositoryId(),
         folderId = aFileListView.getFolderId(),
         folderSelected = folderId !== undefined,
         isSearching = getSearchQueryTerm(searchInputField) !== undefined,
         isTagFiltering = getSelectedTag(tagList) !== undefined,
         isBrowsing = (!isSearching && !isTagFiltering),
         visibleItemOnClipboard = ($(aFileListView.getTable()).find('.sv-cut').length > 0);

   // Menu group 1
   var clipboardItems = getClipboardItems(repositoryId),
         clipboardItemIds = _.keys(clipboardItems),
         itemsOnClipboard = clipboardItemIds.length > 0,
         folderOnClipboard = folderId && _.contains(clipboardItemIds, folderId),
         cutVisible = hasSelectedItemsNotOnClipboard(table),
         pasteVisible = (itemsOnClipboard && !folderOnClipboard && !visibleItemOnClipboard && isBrowsing),
         selectAllVisible = hasUnselectedItems(table),
         group1Visible = (cutVisible || pasteVisible || selectAllVisible);
   updateMenuAlternativeVisibility(cutVisible, table.find('[data-fn-cut]'));
   updateMenuAlternativeVisibility(pasteVisible, table.find('[data-fn-paste]'));
   updateMenuAlternativeVisibility(selectAllVisible, table.find('[data-fn-select-all]'));

   // Menu group 2
   var createFolderVisible = isBrowsing,
         renameFolderVisible = (folderSelected && isBrowsing),
         group2Visible = (createFolderVisible || renameFolderVisible);
   updateElementVisibility(group2Visible && group1Visible, table.find('.sv-fileview-menu-group2-separator'));
   updateMenuAlternativeVisibility(createFolderVisible, table.find('[data-fn-create-folder]'));
   updateMenuAlternativeVisibility(renameFolderVisible, table.find('[data-fn-rename-folder]')); // Only show 'rename' if browsing a folder (and not the repository root)

   // Menu group 3
   var itemsSelected = hasSelectedItems(table),
         deleteVisible = (itemsSelected && isBrowsing),
         group3Visible = deleteVisible;
   updateElementVisibility(group3Visible && (group1Visible || group2Visible), table.find('.sv-fileview-menu-group3-separator'));
   updateMenuAlternativeVisibility(deleteVisible, table.find('[data-fn-delete]'));

   // Menu button
   var menu = table.find('.sv-fileview-dropdown-link');
   if (group1Visible || group2Visible || group3Visible) {
      showElement(menu);
   } else {
      hideElement(menu);
   }
};

// Create folder
var createFolder = function(anInputField, aTagList, aFileListView) {
   dialogUtil.showInputDialog(getText('createFolderTitle'), getText('createFolderMessage'), false, '', function(aData) {
      if (aData.result) {
         var folderName = aData.text;
         var folder = new FileResource({name: folderName});

         aFileListView.collection.create(folder, {
            wait: true,
            success: function() {
               // Clear all filters (always show the newly created folder)
               clearSearchQuery(anInputField);
               clearTagFilterSelection(aTagList);
               reloadFileListView(aFileListView);
            }
         });
      }
   });
};

// Rename folder
var renameFolder = function(aNewName, aFolderId, aPortletId) {
   $.ajax({
      type: 'PUT',
      url : getPortletResourceUri(aPortletId, 'renameFolder') + '&folder=' + aFolderId + '&folderName=' + aNewName,
      success: function() {
         reloadPage();
      },
      error: function(aResponse) {
         errorUtil.handleAjaxFailure(aResponse);
      }
   });
};

// Get the id of an item that a checkbox is associated with
var getItemId = function(anItemCheckbox) {
   return anItemCheckbox.data('id');
};

// Get the name of an item that a checkbox is associated with
var getItemName = function(anItemCheckbox) {
   return anItemCheckbox.data('name');
};

// Move all visible selected items to the clipboard
var moveSelectedItemsToClipboard = function(aTable, aRepositoryId) {
   var items = {};

   aTable.find('.sv-file-checkbox').each(function() {
      var checkbox = $(this);
      var tr = checkbox.closest('tr');

      if (checkbox.prop('checked')) {
         items[getItemId(checkbox)] = getItemName(checkbox);

         if (!tr.hasClass('sv.cut')) {
            tr.addClass('sv-cut');
         }
      } else if (tr.hasClass('sv-cut')) {
         tr.removeClass('sv-cut');
      }
   });

   setClipboardItems(items, aRepositoryId);
};

// Move all clipboard items to a folder (or repository root)
var moveClipboardItems = function(aFolderId, aRepositoryId, aPortletId, aFileListView) {
   var clipboardItems = getClipboardItems(aRepositoryId);
   var itemIds = _.keys(clipboardItems);

   if (itemIds.length > 0) {
      var confirmMessage = (itemIds.length === 1 ? getText('moveNodeSingleMessage', clipboardItems[itemIds[0]]) : getText('moveNodeMultiMessage', itemIds.length));

      dialogUtil.showConfirmDialog(getText('moveNodeTitle'), confirmMessage, function(aResult) {
         if (aResult) {
            $.ajax({
               type: 'POST',
               data: { folder: aFolderId, repository: aRepositoryId, node: itemIds },
               url : getPortletResourceUri(aPortletId, 'moveNode'),
               success: function() {
                  clearClipboardItems(aRepositoryId);
                  reloadFileListView(aFileListView);
               },
               error: function(aResponse) {
                  errorUtil.handleAjaxFailure(aResponse);
               }
            });
         }
      });
   }
};

// Remove all visible selected items
var removeVisibleSelectedItems = function (aTable, aRepositoryId, aPortletId, aFileListView) {
   // Find all visible selected items
   var clipboardItems = getClipboardItems(aRepositoryId);
   var clipboardRemoveItems = [];
   var itemIds = [];
   var itemNames = [];

   aTable.find('.sv-file-checkbox:checked').each(function() {
      var checkbox = $(this);
      var itemId = getItemId(checkbox);
      itemIds.push(itemId);
      itemNames.push(getItemName(checkbox));

      // Store a reference to all visible items that also is present on the clipboard
      if (clipboardItems[itemId] !== undefined) {
         clipboardRemoveItems.push(itemId);
      }
   });

   if (itemIds.length > 0) {
      var confirmMessage = (itemIds.length === 1 ?  getText('deleteNodeSingleMessage', _.escape(itemNames[0])) : getText('deleteNodeMultiMessage', itemIds.length));

      dialogUtil.showConfirmDialog(getText('deleteNodeTitle'), confirmMessage, function(aResult) {
         if (aResult) {
            $.ajax({
               type: 'DELETE',
               url : getPortletResourceUri(aPortletId, 'list') + '&repository=' + aRepositoryId + '&nodes=' + itemIds.join(','),
               success: function() {
                  // Remove items that was removed from the clipboard
                  if (clipboardRemoveItems.length > 0) {
                     var newClipboardItems = _.difference(clipboardItems, clipboardRemoveItems);

                     setClipboardItems(newClipboardItems, aRepositoryId);
                  }

                  reloadFileListView(aFileListView);
               },
               error: function(aResponse) {
                  errorUtil.handleAjaxFailure(aResponse);
               }
            });
         }
      });
   }
};

// Check if a file that is about to get uploaded will be accepted by the server
var verifyFileUpload = function(aName, aSize, aFolderId, aRepositoryId, aPortletId) {
   var verified = false;
   var folderParam = '';

   if (aFolderId !== undefined) {
      folderParam = '&folder=' + aFolderId;
   }

   $.ajax({
      type: 'GET',
      url : getPortletResourceUri(aPortletId, 'verifyUpload') + folderParam,
      async: false,
      data: {
         repository: aRepositoryId,
         name: aName,
         size: aSize
      },
      success: function(aResponse) {
         verified = aResponse.verified;
      },
      error: function(aResponse) {
         errorUtil.handleAjaxFailure(aResponse);
      }
   });

   return verified;
};

// Backbone model for a FileResource
var FileResource = Backbone.Model.extend({
   defaults: {
      name: '',
      iconURL: '/',
      url: '/',
      viewURL: '/'
   },

   getViewUrl: function() {
      return this.get('viewURL');
   },

   url: function() {
      if (this.collection) {
         var fileId = this.get('id');

         if (fileId) {
            return this.collection.url() + '&file=' + fileId;
         } else {
            return this.collection.url();
         }
      }

      return '?fileId=' + this.get('id');
   }
});

// Backbone model for a file or folder
var FileList = Backbone.Collection.extend({
   model: FileResource,

   initialize: function(models, options) {
      this.id = options.id;
      this.portletId = options.portletId;
      this.searchInputField = options.searchInputField;
      this.folderId = options.folderId;
      this.tagList = options.tagList;
      this.table = options.table;
      this.breadcrumbList = options.breadcrumbList;
      this.fileUploadButton = options.fileUploadButton;

      this.sortProperty = 'name';
      this.sortDirection = 'ASC';
   },

   comparator: function(model1, model2) {
      var value1 = model1.get(this.sortProperty),
         value2 = model2.get(this.sortProperty),
         sortValue;

      if (this.sortProperty === 'name' || this.sortProperty === 'modifiedByFullName') {
         value1 = (value1 || '').toLowerCase();
         value2 = (value2 || '').toLowerCase();
      } else if (this.sortProperty === 'byteSize') {
         value1 = value1 || 0;
         value2 = value2 || 0;
      } else if (this.sortProperty === 'date') {
         value1 = sv.DateUtil.fromISO(value1).getTime();
         value2 = sv.DateUtil.fromISO(value2).getTime();
      }

      if (value1 < value2) {
         sortValue = -1;
      } else if (value1 > value2) {
         sortValue = 1;
      } else {
         sortValue = 0;
      }

      if (this.sortDirection === 'DESC') {
         sortValue = -sortValue;
      }

      return sortValue;
   },

   url: function() {
      var term = getSearchQueryTerm(this.searchInputField);
      var tag = getSelectedTag(this.tagList);
      var folderParam = '';

      if (this.folderId !== undefined) {
         folderParam = '&folder=' + this.folderId;
      }

      // TODO move 'view' code elsewhere
      // Show the appropriate breadcrumb when reloading the model
      if (tag !== undefined) {
         var tagId = getTagId(tag);
         hideElement(this.fileUploadButton);
         hideNodeBreadcrumb(this.breadcrumbList);
         hideSearchResultBreadcrumb(this.breadcrumbList);
         showTagBreadcrumb(tag, this.breadcrumbList);
         setHashValue('tag', tagId);
         return getPortletResourceUri(this.portletId, 'tagSearch') + folderParam + '&tag=' + tagId;
      } else if (term !== undefined) {
         hideElement(this.fileUploadButton);
         hideNodeBreadcrumb(this.breadcrumbList);
         hideTagBreadcrumb(this.breadcrumbList);
         showSearchResultBreadcrumb(this.breadcrumbList);

         var encodedTerm = encodeURIComponent(term);
         setHashValue('term', encodedTerm);

         return getPortletResourceUri(this.portletId, 'search') + folderParam + '&term=' + encodedTerm;
      } else {
         showElement(this.fileUploadButton);
         hideSearchResultBreadcrumb(this.breadcrumbList);
         hideTagBreadcrumb(this.breadcrumbList);
         showNodeBreadcrumb(this.breadcrumbList);
         clearHashValue();
         return getPortletResourceUri(this.portletId, 'list') + folderParam;
      }
   }
});

// Backbone view for a file or folder
var FileResourceView = Backbone.View.extend({
   tagName: 'tr',

   className: '',

   events: {
      'click [data-fn-destroy]': 'destroy'
   },

   destroy: function() {

      this.model.destroy({
         wait: true
      });
      return false;
   },

   navigate: function() {
      window.location.href = this.model.getViewUrl();
      return;
   },

   render: function() {
      this.$el.html(this.options.template(this.model.toJSON()));
      return this;
   }

});

// Backbone view for a list of files
var FileListView = Backbone.View.extend({
   events: {
      'keyup [data-fn-search-filter]'  : 'setSearchFilter',
      'click [data-fn-file-tag]'       : 'setTagFilter',
      'click [data-fn-sort]'           : 'updateSort'
   },

   initialize: function() {
      this.$list = this.options.list;
      this.portletId = this.options.portletId;
      this.searchInputField = this.options.searchInputField;
      this.tagList = this.options.tagList;
      this.table = this.options.table;
      this.editable = this.options.editable;

      if (this.collection.length > 0) {
         this.render();
      }

      this.listenTo(this.collection, 'add', this.prependOne);
      this.listenTo(this.collection, 'sort', this.handleSort);
   },

   updateSort: function(event) {
      var $currentTarget = $(event.currentTarget),
         sortProperty = $currentTarget.data('fn-sort');

      this.table.find('.sv-sort-toggle').removeClass('sv-sort-desc sv-sort-asc');

      if (sortProperty === this.collection.sortProperty) {
         this.collection.sortDirection = this.collection.sortDirection === 'ASC' ? 'DESC' : 'ASC';
      } else {
         this.collection.sortProperty = sortProperty;
         this.collection.sortDirection = 'ASC';
      }

      $currentTarget.addClass(this.collection.sortDirection === 'ASC' ? 'sv-sort-asc' : 'sv-sort-desc');

      this.collection.sort();
   },

   handleSort: function() {
      this.render();
      updateBootstrapTooltips(this);
      updateMenuVisibility(this);
      updateVisibleClipboardItems(this);
   },

   getTable: function() {
      return this.table;
   },

   getSearchInputField: function() {
      return this.searchInputField;
   },

   getTagList: function() {
      return this.tagList;
   },

   getRepositoryId: function() {
      return this.table.data('repository');
   },

   getFolderId: function() {
      return this.table.data('folder');
   },

   getEditable: function() {
      return this.editable;
   },

   render: function() {
      this.clearList();

      this.collection.each(function(item) {
         this.appendOne(item);
      }, this);

      events.trigger(events.types.updateRelativeDates, this.$list);
   },

   appendOne: function(file) {
      var view = new FileResourceView({
         model: file,
         portletId: this.options.portletId,
         template: this.options.fileTemplate,
         editable: this.options.editable,
         table: this.options.table
      });
      this.$list.append(view.render().el);
   },

   prependOne: function(file) {
      var view = new FileResourceView({
         model: file,
         portletId: this.options.portletId,
         template: this.options.fileTemplate,
         editable: this.options.editable
      });
      this.$list.prepend(view.render().el);
      events.trigger(events.types.updateRelativeDates, this.$list);
   },

   clearList: function() {
      this.$list.empty();
   },

   filterOne: function(file, filter) {
      file.trigger('filter', {'filter': filter});
   },

   filterAll: function(filter) {
      this.collection.each(function(element) {
         this.filterOne(element, filter);
      }, this);
   },

   // Execute a server side tag search and re-render file view with the result
   setTagFilter: function(e) {
      var tag = $(e.currentTarget).closest('li');
      var tagWasSelected = isTagSelected(tag);
      clearSearchQuery(this.searchInputField);
      clearTagFilterSelection(this.tagList);

      if (!tagWasSelected) {
         setTagSelected(tag);
      }

      // Reload and update list view
      var view = this;
      reloadFileListView(view);
      return false;
   },

   // Execute a server side search and re-render file view with the result
   setSearchFilter: function() {
      var fileListView = this;

      if (searchTimer !== undefined) {
         clearTimeout(searchTimer); // Reset timer every time a key is pressed
      }

      clearTagFilterSelection(this.tagList);

      searchTimer = setTimeout(function() {
         reloadFileListView(fileListView);
      }, 200);
   }
});

$('.sv-fileshareall-portlet').each(function () {
   import(/* webpackChunkName: "fileview-plugins" */ './plugins').then(
     () => {
      var portletDiv = $(this),
            portletId = objectUtil.getObjectId(portletDiv.attr('id')),
            table = portletDiv.find('table.sv-file-list'),
            repositoryId = table.data('repository'),
            searchInputField = portletDiv.find('.sv-search-filter-field'),
            tagList = portletDiv.find('.sv-filebrowse-taglist'),
            breadcrumbList = portletDiv.find('.sv-breadcrumb'),
            editable = table.data('editable'),
            folderId = (table.data('folder') !== '' ? table.data('folder') : undefined), // Only set if browsing a folder (and not the repository root)
            templateName = table.data('template'),
            containerId = table.data('container'),
            modelObjectUrl = getModelObjectUri(containerId, 'fileUpload'),
            fileUploadForm = portletDiv.find('.sv-fileview-upload-form'),
            fileUploadButton = fileUploadForm.find('.sv-fileview-upload-button'),
            fileUploadProgressElem =  portletDiv.find('.sv-fileview-upload-progress'),
            fileUploadProgressTimer = null,
            jsNamespace = table.data('js-namespace'),
            template = getTemplate(portletDiv, templateName),
            fileList = new FileList(sv[jsNamespace +'_files'] || [], {
               id: containerId,
               portletId: portletId,
               folderId : folderId,
               searchInputField: searchInputField,
               tagList: tagList,
               table: table,
               breadcrumbList: breadcrumbList,
               fileUploadButton: fileUploadButton
            }),
            fileListView = new FileListView({
               el: portletDiv,
               list: table.find('tbody'),
               collection: fileList,
               fileTemplate: template,
               portletId: portletId,
               editable: editable,
               searchInputField : searchInputField,
               table: table,
               tagList: tagList
            });

      // Show search field label if placeholders isn't supported
      if (!clientUtil.supportsPlaceholder) {
         portletDiv.find('.sv-search-filter-label').removeClass('sv-hidden');
      }

      // Update list content if a hash portion is available in the URL (bookmarked URL or forward/back in browser)
      if (hasHashValue()) {
         var term = getHashValue('term');
         var tagId = getHashValue('tag');

         if (term !== undefined) {
            var decodedTerm = decodeURIComponent(term);

            if (decodedTerm.length > 0) {
               searchInputField.val(decodedTerm);
               searchInputField.focus();
               reloadFileListView(fileListView);
            }
         } else if (tagId !== undefined) {
            var tag = getTagById(tagId, tagList);

            if (tag !== undefined) {
               setTagSelected(tag);
               reloadFileListView(fileListView);
            }
         }
      }

      // Initialize interactive functions if allowed to modify files or folders
      if (editable) {
         // Mark items that already is selected
         if (hasClipboardItems(repositoryId)) {
            updateVisibleClipboardItems(fileListView);
         }

         // Bind menu alternatives
         portletDiv.on('click', '[data-fn-cut]', function() {
            moveSelectedItemsToClipboard(table, repositoryId);
         });

         portletDiv.on('click', '[data-fn-paste]', function() {
            moveClipboardItems(containerId, repositoryId, portletId, fileListView);
         });

         portletDiv.on('click', '[data-fn-select-all]', function() {
            $('.sv-file-checkbox').prop('checked', true);
         });

         portletDiv.on('click', '[data-fn-create-folder]', function() {
            createFolder(searchInputField, tagList, fileListView);
         });

         portletDiv.on('click', '[data-fn-rename-folder]', function() {
            var renameLink = $(this);

            dialogUtil.showInputDialog(getText('renameFolderTitle'), getText('renameFolderMessage'), false, renameLink.data('name'), function(aData) {
               if (aData.result) {
                  renameFolder(aData.text, folderId, portletId);
               }
            });
         });

         portletDiv.on('click', '[data-fn-delete]', function() {
            removeVisibleSelectedItems(table, repositoryId, portletId, fileListView);
         });

         portletDiv.on('click', '.sv-fileview-dropdown-link', function() {
            updateMenuVisibility(fileListView);
         });

         portletDiv.on('click', '.sv-file-checkbox', function() {
            var $cut = $(this).closest('.sv-cut');
            if ($cut.length > 0) {
               $cut.removeClass('sv-cut');
               moveSelectedItemsToClipboard(table, repositoryId);
            }
         });

         //portletDiv.find('.sv-fileview-dropdown-link').tooltip().on('click', function() { $(this).tooltip('hide'); } );
      }

      sv.Events.on(sv.Events.types.fileAdded, function() {
         fileListView.setSearchFilter.call(fileListView);
      });

      // TODO move verify code to a file upload utility and share with fileview-fileview-portlet.js and filelist-portlet.js (possibly others as well).
      fileUploadForm.fileupload({
         dataType: 'json',
         url: modelObjectUrl,  // will do a POST of multi-parts (see FileUploadHandler.uploadParts)
         replaceFileInput: true,
         formAcceptCharset: 'utf-8',
         dropZone: portletDiv,

         // before posting the data
         submit: function(e, data) {
            var file = data.files[0],
               fileSize = file.size;

            // IE8/9 workaround (no size support)
            if (!fileSize) {
               fileSize = 1;
            }

            // Make sure that the file that is about to get uploaded is accepted
            if (!verifyFileUpload(file.name, fileSize, folderId, repositoryId, portletId)) {
               return false;
            }

            fileUploadProgressTimer = setTimeout(function() {
               showElement(fileUploadProgressElem);
            }, 500);
         },

         // File upload was successful
         done: function () {
            // Reset tag filter and query (newly uploaded file should always be visible)
            clearTagFilterSelection(tagList);
            clearSearchQuery(searchInputField);
            reloadFileListView(fileListView);
         },

         // post failed (e.g. server not available)
         fail: function() {
            return handleError(getCommonText('error-unknown'));
         },

         // after post (failed or successful)
         always: function() {
            if (fileUploadProgressTimer !== null) {
               clearTimeout(fileUploadProgressTimer);
            }

            hideElement(fileUploadProgressElem);
         }
      });
   });
});
