/**
* Copyright (C) SiteVision AB 2002-2020, all rights reserved
*
* Contact search all portlet functions.
* @author Magnus Lövgren
*/
import $ from '@sv/jquery';
import sv from '@sv/core';
import {getTemplate} from '../../util/portletUtil';
import {
  Ajax as ajax,
  ErrorUtil as errorUtil,
  ObjectUtil as objectUtil,
} from '@sv/util';

$('.sv-contactsearchall-portlet').each(function() {
  const $portlet = $(this),
    $searchForm = $portlet.find('[data-fn-search-form]'),
    $searchField = $portlet.find('[data-fn-search-field]'),
    $searchHiddenCheckBox = $portlet.find('[data-fn-search-hidden-checkbox]'),
    $errorDiv = $portlet.find('[data-fn-no-result-container]'),
    $hitList = $portlet.find('[data-fn-search-result-container]'),
    $loadMoreDiv = $portlet.find('[data-fn-load-more-container]'),
    searchMode = $searchForm.data('search-mode'),
    handleErrors = $errorDiv.length > 0,
    portletId = objectUtil.getObjectId($portlet.attr('id')),
    hitTemplateFunction = getTemplate($portlet, 'social-entry'),
    entryConnectedTemplateFunction = getTemplate($portlet, 'social-entry-connected'),
    portletStateBase = '/'+ sv.PageContext.pageId +'/'+ portletId +'.json?state=',
    moreTemplateFunction = getTemplate($portlet, 'more-hits');

  let errorTemplateFunction,
    groupId,
    $selectAllDiv,
    $addMembersDiv,
    addMembersBase,
    addContactBase,
    queryBase,
    searchTimer;

  if (searchMode === 'group') {
    groupId = $searchForm.attr('data-group-id');
    $selectAllDiv = $portlet.find('[data-select-all-container]');
    $addMembersDiv = $portlet.find('[data-add-members-container]');
    addMembersBase = portletStateBase +'ajaxAddMembers&group='+ groupId +'&identitiesParam=';
    queryBase = portletStateBase +'ajaxQuery&group='+ groupId +'&query=';

    // Set initial value for group components...
    updateGroupComponents($selectAllDiv, $hitList, $addMembersDiv);
  } else {
    addContactBase = portletStateBase +'ajaxAddContact&identitiesParam=';
    queryBase = portletStateBase +'ajaxQuery&query=';
  }

  if (handleErrors) {
    errorTemplateFunction = getTemplate($portlet, 'error');
  }

  // Disable potential browser autocomplete...
  $searchField.attr('autocomplete', 'off');

  // Disable form submit via enter (use keyup)
  $searchForm.on('submit', function() {
    return false;
  });

  function doSearch() {
    const qry = $searchField.val(),
      queryLength = qry.length;

    if (handleErrors) {
      $errorDiv.empty(); // Clear error messages ASAP
    }

    if (queryLength === 0 || qry === '*' || qry === '+' || qry === '-') {
      $hitList.empty();
      $loadMoreDiv.empty();
      if (searchMode === 'group') {
        updateGroupComponents($selectAllDiv, $hitList, $addMembersDiv);
      }
    } else {
      const encodedQry = encodeURIComponent(qry); // Ensure '+' chars is encoded (might be treated as space char otherwise)
      let getURL = queryBase + encodedQry;
      if ($searchHiddenCheckBox.prop('checked')) {
        getURL += '&searchHidden=true';
      }

      ajax.doGet({
        url: getURL,
        success: function(result) {
          // Ensure result is still valid...
          if (result.queryString === $searchField.val() || result.queryString === encodeURIComponent($searchField.val())) {
            $hitList.empty();
            $loadMoreDiv.empty();

            if (result.hits) {
              $.each(result.hits, function(index, hit) {
                $hitList.append(hitTemplateFunction(hit));
              });
              if (result.hasMoreHits) {
                $loadMoreDiv.append(moreTemplateFunction(result));
              }
            } else if (handleErrors && result.message) {
              $errorDiv.empty().append(errorTemplateFunction(result));
            }

            if (searchMode === 'group') {
              updateGroupComponents($selectAllDiv, $hitList, $addMembersDiv);
            }
          }
        }
      });
    }
  }

  // Instant search whenever user types in search field
  $searchField.on('input', function() {
    // Use timer to do requests after a certain period (avoid 'user keeps typing' problem...)
    searchTimer && clearTimeout(searchTimer);
    searchTimer = setTimeout(doSearch,200);
    return false;
  });

  //redo search when a admin checks "search hidden and disabled"-checkbox and show/hide elements that should not be visible.
  if($searchHiddenCheckBox.length > 0) {
    $searchHiddenCheckBox.on('click', doSearch); //TODO do not call search but searchHidden function that delegates to doSearch!!!
  }

  // User presses "More hits" button...
  $loadMoreDiv.on('click', '[data-fn-load-more]', function() {
    const $this = $(this),
      qry = $searchField.val(),
      nextHit = $this.attr('data-next-hit');

    let moreURL = queryBase + encodeURIComponent(qry) + '&startAtHit=' + nextHit;

    if ($searchHiddenCheckBox.prop('checked')) {
      moreURL += '&searchHidden=true';
    }
    $this.attr('disabled', 'disabled'); // Disable button ASAP

    ajax.doGet( {
      url: moreURL,
      success: function(result) {
        $loadMoreDiv.empty();

        if (result.hits) {
          $.each(result.hits, function(index, hit) {
            $hitList.append(hitTemplateFunction(hit));
          });
          if (result.hasMoreHits) {
            $loadMoreDiv.append(moreTemplateFunction(result));
          }
        } else if (handleErrors && result.message) {
          // Note! Potential "previous" hits still remains... [No $hitList.empty() here...]
          $errorDiv.empty().append(errorTemplateFunction(result));
        }

        if (searchMode === 'group') {
          updateGroupComponents($selectAllDiv, $hitList, $addMembersDiv);
        }
      }
    });
  });

  if (searchMode === 'contact') {

    // User presses "Add as contact" button...
    $hitList.on('click', '[data-fn-add-contact]', function() {
      const $this = $(this),
        contact = $this.data('contact-id'),
        addURL = addContactBase + contact;

      $this.attr('disabled', 'disabled'); // Disable button ASAP

      ajax.doGet( {
        url: addURL,
        success: function(result) {
          if(result.success === 'true') {
            var selector;
            if (result.added) {
              selector = '[data-contact-id="' + result.added + '"]';
              $(selector).closest('.sv-function-container').html(entryConnectedTemplateFunction(result));
            }
          } else {
            errorUtil.handleAjaxFailure(result);
          }
        },
      });
    });

  } else if (searchMode === 'group') {

    // User presses "Select all" button...
    $selectAllDiv.on('click', '[data-fn-select-all]', function() {
      const $button = $(this),
        status = $button.attr('data-toggle-status');

      $hitList.find('[data-fn-select-member]').each(function() {
        var $checkBox = $(this);

        if (status === 'off') {
          $checkBox.removeAttr('checked');
        } else {
          $checkBox.attr('checked', 'checked');
        }
      });

      updateGroupComponents($selectAllDiv, $hitList, $addMembersDiv);
    });

    // User clicks "Mark this identity" checkbox...
    $hitList.on('change', '[data-fn-select-member]', function() {
      updateGroupComponents($selectAllDiv, $hitList, $addMembersDiv);
    });

    // User presses "Add selected to group" button
    $addMembersDiv.on('click', '[data-fn-add-members]', function() {
      const $button = $(this),
        $selectedBoxes = $hitList.find('[data-fn-select-member]:checked');

      if ($selectedBoxes.length > 0) {
        let addURL = addMembersBase;

        $button.attr('disabled', 'disabled'); // Disable button ASAP

        $selectedBoxes.each(function() {
          const $box = $(this),
            identity = $box.attr('data-contact-id');

          if (identity) {
            addURL += identity + '_';
          }
        });

        ajax.doGet( {
          url: addURL,
          success: function(result) {
            var selector;
            if (result.added) {
              selector = '[data-contact-id="' + result.added.join('"],[data-contact-id="') + '"]';
              $(selector).closest('.sv-function-container').html(entryConnectedTemplateFunction(result));
            }

            updateGroupComponents($selectAllDiv, $hitList, $addMembersDiv);
          }
        });
      }
    });

  }

});

function updateSelectAllButtonText($toggleButton, newStatus) {
  const onText = $toggleButton.data('on-status-text'),
    offText = $toggleButton.data('off-status-text'),
    oldText = $toggleButton.html();

  let newText;

  $toggleButton.attr('data-toggle-status', newStatus);

  if (newStatus === 'on') {
    if (oldText.indexOf(onText) === -1) {
      newText = oldText.replace(offText, onText);
      $toggleButton.html(newText);
    }
  } else {
    if (oldText.indexOf(offText) === -1) {
      newText = oldText.replace(onText, offText);
      $toggleButton.html(newText);
    }
  }
}

function updateGroupComponents($selectButtonDiv, $checkBoxesList, $addButtonDiv) {
  var $toggleButton = $($selectButtonDiv.find('[data-fn-select-all]')[0]), // Locate "select all" button
  $addButton = $($addButtonDiv.find('[data-fn-add-members]')[0]), // Locate "add members" button
  $checkBoxes = $checkBoxesList.find('[data-fn-select-member]'), // Locate all checkboxes
  hasSearchResult = $checkBoxesList.find('li').length > 0, // Check if there are any search result
  hasCheckBoxes = $checkBoxes.length > 0;

  if (hasSearchResult) {
    $toggleButton.removeClass('svhidden');
    $addButton.removeClass('svhidden');

    if (hasCheckBoxes) {
      $toggleButton.prop('disabled', false);
      $addButton.prop('disabled', false);

      const total = $checkBoxes.length;
      let checked = 0;

      $checkBoxes.each(function() {
        if ($(this).is(':checked')) {
          checked++;
        }
      });

      // Update components when 'no selection' or 'all selection'
      if (checked === 0) {
        $addButton.attr('disabled', 'disabled');
        updateSelectAllButtonText($toggleButton, 'on');
      } else if (checked === total) {
        updateSelectAllButtonText($toggleButton, 'off');
      }
    } else {
      updateSelectAllButtonText($toggleButton, 'on');
      $toggleButton.attr('disabled', 'disabled');
      $addButton.attr('disabled', 'disabled');
    }
  } else {
    $toggleButton.addClass('svhidden');
    $addButton.addClass('svhidden');
  }

}
