/*jshint nonew:false */
/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 *
 * Handles listing and filtering of a Group members.
 * Works alongside with groupadminall.css and groupadminall.vm
 */
import Backbone from '@sv/backbone';
import $ from '@sv/jquery';
import sv from '@sv/core';
import {showSearchPopover} from '../util/SearchUserUtil';
import {
  ObjectUtil as objectUtil,
  ClientUtil as clientUtil,
  DialogUtil as dialogUtil,
  Events as events,
  ErrorUtil as errorUtil,
  i18n as _i18n,
} from '@sv/util';
import {
  getPortletResourceUri,
  getTemplate
} from '../../util/portletUtil';

const i18cache = {};

const i18n = function(selector, args) {
   let translated;

   if (!args && i18cache[selector]) {
      return i18cache[selector];
   }

   translated = _i18n.getText('portlet.social.groupadminall.groupadminall', selector, args);

   if (!args) {
      i18cache[selector] = translated;
   }
   return translated;
};

const Member = Backbone.Model.extend({
   defaults: {
      fullName: '',
      profileImageURL: '',
      userFields: []
   },

   getProfilePageUrl: function() {
      return this.get('showProfileURL');
   },

   url: function() {
      var memberId = this.get('id');
      if (this.collection) {
         return this.collection.url() + '&member=' + memberId;
      } else {
         return '?member=' + memberId;
      }
   }

});

const Group = Backbone.Collection.extend({

   model: Member,

   initialize: function(models, options) {
      this.id = options.id;
      this.portletId = options.portletId;
   },

   url: function() {
      return getPortletResourceUri(this.portletId, 'members');
   },

   comparator: function(modelObject) {
      if (modelObject.get('fullName') !== null)
      {
         return modelObject.get('fullName').toLowerCase();
      } else
      {
         return null;
      }
   }
});

const MemberView = Backbone.View.extend({

   tagName: 'li',

   className: 'sv-clearfix sv-social-contact sv-groupadminall-member',

   events: {
      'click [data-fn-destroy]': 'destroy',
      'mousedown [data-fn-downdown-toggle]': 'openMenu',
      'click': 'navigate'
   },

   initialize: function() {
      this.bindModelEvents();
   },

   bindModelEvents: function() {
      this.model.on('filter', this.toggleVisible, this);
      this.model.on('destroy', this.remove, this);
   },

   openMenu: function(e) {
      this.$el.find('.downdown-toggle').dropdown('toggle');
      e.stopPropagation();
      return false;
   },

   destroy: function() {
      var that = this;

      dialogUtil.showConfirmDialog(i18n('confirmRemoveTitle'), i18n('confirmRemoveMessage', this.model.get('fullName')), function(result) {
         if (result) {
            that.model.destroy({
               wait: true,
               error: function(model, xhr, options) {
                  errorUtil.handleBackboneError(model,xhr,options);
               }
            });
         }
      });

      return false;
   },

   navigate: function(e) {
      if ((clientUtil.isMac && e.metaKey) ||
         (!clientUtil.isMac && e.ctrlKey) ||
         e.shiftKey) {
         window.open(this.model.getProfilePageUrl());
      } else {
         window.location.href = this.model.getProfilePageUrl();
      }
      return;
   },

   toggleVisible: function(opts) {
      this.$el.toggleClass('sv-hidden', !this.isVisible(opts.filter));
   },

   isVisible: function(filter) {
      var contact = this.model.get('fullName') + this.model.get('memberStatus') + this.model.get('userFields').join('|');
      return !filter || (contact && contact.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
   },

   remove: function() {
      var that = this;
      this.$el.fadeOut('3000', function() {
         that.$el.remove();
         events.trigger(events.types.notifyUser, {
            'type'      : 'success',
            'message'   : i18n('successMessage', that.model.get('fullName')),
            'transient' : true,
            'timer'     : 5
         });
      });
   },

   render: function() {
      this.$el.html(this.options.template(this.model.toJSON()));
      return this;
   }

});

const GroupView = Backbone.View.extend({

   events: {
      'click [data-fn-switch-list-style]' : 'setListStyle',
      'click [data-fn-add-member]'        : 'addNewMember',
      'keyup [data-fn-filter]'            : 'setFilter',
      'change [data-fn-filter]'           : 'setFilter',
      'blur [data-fn-filter]'             : 'setFilter'
   },

   initialize: function() {
      this.$list = this.$el.find('ol');
      this.listStyle = this.options.listStyle;
      this.portletId = this.options.portletId;
      this.groupId = this.options.groupId;
      this.searchResultURL = this.options.searchResultURL;

      if (this.collection.length > 0) {
         this.render();
      }

      this.bindCollectionEvents();

   },

   addNewMember: function(e) {
      var that = this;
      showSearchPopover({
         target: e.currentTarget,
         searchUrl: that.searchResultURL,
         group: that.groupId,
         placeholder: i18n('searchUser'),
         selected: function(value) {
            //console.log(value);
            var member = new Member(value);
            that.collection.create(member, {
               wait: true,
               success: function() {
                  // add members
                  events.trigger(events.types.notifyUser, {
                     'type'      : 'success',
                     'message'   : i18n('successAddMessage', value.name),
                     'transient' : true,
                     'timer'     : 5
                  });
               },
               error: function(model, xhr, options) {
                  errorUtil.handleBackboneError(model,xhr,options);
               }
            });
         }
      });
      return false;
   },

   bindCollectionEvents: function() {
      this.collection.on('add', this.appendOne, this);
   },

   render: function() {
      this.clearList();

      this.collection.each(function(item) {
         this.appendOne(item);
      }, this);
   },

   appendOne: function(member) {
      var view = new MemberView({
         model: member,
         portletId: this.options.portletId,
         template: this.options.memberTemplate
      });
      this.$list.append(view.render().el);
   },

   filterOne: function(member, filter) {
      member.trigger('filter', {'filter': filter});
   },

   filterAll: function(filter) {
      this.collection.each(function(element) {
         this.filterOne(element, filter);
      }, this);
   },

   clearList: function() {
      this.$list.empty();
   },

   setListStyle: function(e) {
      var newListStyle = $(e.currentTarget).data('list-style');
      this.$list.removeClass(this.listStyle).addClass(newListStyle);
      this.listStyle = newListStyle;
   },

   setFilter: function(e) {
      this.filterAll(e.currentTarget.value);
   }

});

$('.sv-groupadminall-portlet').each(function() {
   var $this = $(this),
      portletId = objectUtil.getObjectId($this.attr('id')),
      $list = $this.find('ol'),
      groupId = $list.data('group'),
      searchResultURL = $list.data('search-result-url'),
      jsNamespace = $list.data('js-namespace'),
      template = getTemplate($this, 'member'),
      group = new Group(sv[jsNamespace] || [], {
         id: groupId,
         portletId: portletId
      });

      new GroupView({
         el: $this,
         collection: group,
         groupId: groupId,
         listStyle: 'sv-list-2-columns',
         memberTemplate: template,
         portletId: portletId,
         searchResultURL: searchResultURL
      });

   $this.find('li.sv-groupadminall-member').css('height', $list.data('item-height'));

   if (!clientUtil.supportsPlaceholder) {
      $this.find('.sv-filter-label').removeClass('sv-hidden');
   }
});