/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * dialog utils (i.e. setup modal backbone dialogs)
 *
 * @author micke
 */
import sv from '@sv/core';
import $ from '@sv/jquery';
import _ from '@sv/underscore';
import {
  getModelObjectUri,
  reloadPortletsByType,
} from '../../../util/portletUtil';
import {
  DialogUtil as dialogUtil,
  Ajax as ajax,
  i18n as _i18n,
} from '@sv/util';

let i18n = function (selector) {
  return _i18n.getText('portlet.social.userfields.userfields', selector);
};
var i18nCommon = function (selector) {
  return _i18n.getText('common', selector);
};
var checkValidity = function (element) {
  var ret = true;
  if (typeof element.checkValidity === 'function') {
    ret = element.checkValidity();
  }
  return ret;
};

function serializeForm($form) {
  var data = $form.serializeArray(),
    result = {};

  $.each(data, function (i, object) {
    result[object.name] = object.value;
  });

  return result;
}

function createSearchChoice(term, data) {
  var localeCompare = function () {
    return this.text.localeCompare(term) === 0;
  };

  if (term.length <= 100 && $(data).filter(localeCompare).length === 0) {
    return { id: term, text: term };
  }
}

export const popupUserSettingsDialog = function (fields) {
  var modalContent = $('<div/>').addClass('sv-userfields-content');
  var form = $('<form/>').addClass('form-horizontal sv-userfields-form');
  modalContent.append(form);

  ajax.doGet({
    url: getModelObjectUri(
      sv.PageContext.userIdentityId,
      'userfields',
      sv.PageContext.userIdentityId
    ),
    data: { fields: fields || 'all' },
    success: function (response) {
      $.each(response.fields, function (index, field) {
        var group = $('<div/>').addClass('control-group');
        form.append(group);

        var label = $('<label/>').addClass('control-label').text(field.name);
        group.append(label);

        var controls = $('<div/>').addClass('controls');
        group.append(controls);
        if (field.writeable === 'true') {
          var inputField;
          if (field.type === 'tags') {
            inputField = $('<input/>', {
              type: 'hidden',
              value: '',
              id: field.id,
              name: field.id,
              'data-errorMsg': field.validationErrorMessage,
            }).addClass('input-xlarge');
          } else if (field.type === 'text_field') {
            inputField = $('<textarea/>')
              .attr({
                rows: '3',
                id: field.id,
                name: field.id,
                'data-errorMsg': field.validationErrorMessage,
              })
              .addClass('input-xlarge');
          } else {
            inputField = $('<input/>')
              .attr({
                type: field.type,
                id: field.id,
                name: field.id,
                'data-errorMsg': field.validationErrorMessage,
              })
              .addClass('input-xlarge');
          }

          if (field.validationType !== 'no_validation') {
            if (
              field.validationType === 'reg_exp_validation' &&
              field.regularExpression
            ) {
              inputField.attr('pattern', field.regularExpression);
            }
            if (field.value) {
              inputField.attr('required', '');
            }
            inputField.attr('data-validation', 'true');
          }
          controls.append(inputField);
          if (field.value) {
            inputField.val(field.value);
          }

          if (field.description) {
            var description = $('<span/>')
              .addClass('help-block help')
              .text(field.description);
            controls.append(description);
          }
        } else {
          if (field.value) {
            var fieldName = $('<p/>', {
              class: 'control-label sv-userfields-text',
              id: field.id,
              text: field.name,
            });
            var fieldValue = $('<p/>', {
              class: 'sv-userfields-text',
              title: field.description,
              'aria-labelledby': field.id,
              css: {
                'padding-top': '5px',
              },
              text:
                field.type === 'tags'
                  ? field.tagsDisplayValue.replace(/,/g, ', ')
                  : field.value,
            });
            group.append(fieldName);
            group.append(controls);
            controls.append(fieldValue);
          }
        }
      });

      if (response.passwordRequired) {
        var group = $('<div/>').addClass('control-group').addClass('well');
        form.append(group);
        var label = $('<label/>')
          .addClass('control-label')
          .text(i18n('password_field_title'));
        group.append(label);
        var controls = $('<div/>').addClass('controls');
        group.append(controls);
        var inputField = $('<input/>')
          .attr({ id: 'password', name: 'password', type: 'password' })
          .addClass('input-xlarge');

        controls.append(inputField);
        var description = $('<span/>')
          .addClass('help-block help')
          .text(i18n('password_field_description'));
        controls.append(description);
      }

      form
        .find('input')
        .not('[type="password"]')
        .on('keyup', function (event) {
          var jqObj = $(event.target);

          if (jqObj.val().length && jqObj.attr('data-validation')) {
            jqObj.attr('required', 'required');
          } else {
            jqObj.prop('required', false);
          }
        });

      var buttons = [
        {
          text: i18nCommon('cancel'),
        },
        {
          text: i18nCommon('ok'),
          skipDismiss: true,
          primary: true,
          callback: function () {
            $('.help-block').remove('.error');
            $('.help-block').remove('.serverside-error');
            $('.control-group').removeClass('error');
            $('.alert').hide();

            if (checkValidity(form.get(0))) {
              //do browser validation
              // save fields
              var formData = serializeForm(form);

              ajax.doPut({
                url: getModelObjectUri(
                  sv.PageContext.userIdentityId,
                  'userfields',
                  sv.PageContext.userIdentityId
                ),
                data: JSON.stringify(formData),
                processData: false,
                success: function (data) {
                  if (data.success) {
                    reloadPortletsByType('.sv-userfields-portlet');
                    reloadPortletsByType('.sv-profileprogress-portlet');
                    $('.modal').modal('hide');
                  } else {
                    if (data.serverError) {
                      var $alert = $('.alert');
                      $alert.show();
                      $alert.find('.alert-title').text(i18nCommon('error'));
                      $alert
                        .find('.alert-message')
                        .text(i18n(data.serverError));

                      if (data.serverError === 'wrong_password') {
                        var inputPassword = modalContent.find('#password');
                        inputPassword
                          .parents('.control-group')
                          .addClass('error');
                        inputPassword.focus();
                      }
                    } else {
                      //Server side validation errors for user fields
                      $.each(data.failedFields, function (index, fieldId) {
                        var failedField = $('#' + fieldId);
                        failedField.parents('.control-group').addClass('error');

                        if (!failedField.nextAll('.serverside-error').length) {
                          failedField.nextAll('.help-block.error').remove();
                          failedField.after(
                            $('<span/>')
                              .addClass('help-block help serverside-error')
                              .text(failedField.attr('data-errorMsg'))
                          );
                        }
                      });
                    }
                  }
                },
                error: function () {
                  $('.alert').show();
                },
              });
            } else {
              //browser validation failed
              var failedField = $('#password');

              if (failedField.length && !checkValidity(failedField.get(0))) {
                failedField.get(0).focus();
                failedField.parents('.control-group').addClass('error');

                $('.alert').show();
                $('.alert').find('.alert-title').text(i18nCommon('error'));
                $('.alert').find('.alert-message').text(i18n('wrong_password'));
              }

              $.each(response.fields, function (index, field) {
                failedField = $('#' + field.id);

                if (
                  field.writeable === 'true' &&
                  !checkValidity(failedField.get(0))
                ) {
                  failedField.get(0).focus();
                  failedField.parents('.control-group').addClass('error');
                  if (
                    !failedField.nextAll('.serverside-error, .error').length
                  ) {
                    failedField.after(
                      $('<span/>')
                        .addClass('help-block help error')
                        .text(failedField.attr('data-errorMsg'))
                    );
                  }
                }
              });
            }
          },
        },
      ];
      var modal = dialogUtil.showDialog({
        title: i18n('dialogTitle'),
        body: modalContent,
        buttons: buttons,
        removeOnHide: true,
        errorTitle: i18n('errorTitle'),
        errorMsg: i18n('errorMsg'),
      });

      var tagFields = _.where(response.fields, { type: 'tags' });

      for (var i = 0; i < tagFields.length; i++) {
        var tagField = tagFields[i];

        if (tagField.writeable === 'true') {
          modal.find('#' + tagField.id).select2({
            multiple: true,
            data: tagField.options,
            tokenSeparators: [',', ' ', '#'],
            createSearchChoice:
              tagField.allowCustomTags &&
              function (term, data) {
                return createSearchChoice(term, data);
              },
          }); // jshint ignore:line
        }
      }
    },
  });
};

sv.UserSettingsUtil = {
  popupUserSettingsDialog: popupUserSettingsDialog,
};
