/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 */

import _ from '@sv/underscore';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import { getPortletResourceUri } from '../../../util/portletUtil';
import {
  Events as events,
  Ajax as ajax,
  DialogUtil as dialogUtil,
} from '@sv/util';
import { toggleLike } from '../../util/likableUtil';
import {
  fixMentionLinks,
  fixTagLinks,
  inputConfig,
  i18nTimeline,
  toggleCommentsTemplate,
} from '../utils.js';
import CommentsView from './Comments';
import CommentsCollection from '../collection/Comments';

const Comment = Backbone.View.extend({
  tagName: 'li',

  className: 'sv-clearfix sv-timeline-comment',

  events: function () {
    if (this.model.get('isSubComment')) {
      return {
        'click [data-fn-portlet-likable]': 'like',
        'click [data-fn-destroy-comment]': 'showDestroyDialog',
        'click [data-fn-edit-comment]': 'editComment',
      };
    }

    return {
      'click [data-fn-portlet-likable]': 'like',
      'click [data-fn-destroy-comment]': 'showDestroyDialog',
      'click [data-fn-edit-comment]': 'editComment',
      'click [data-fn-open-sub-comments]': 'openSubComments',
      'click [data-fn-toggle-sub-comments]': 'toggleSubComments',
    };
  },

  initialize: function () {
    this.listenTo(this.model, 'change:comment', this.updateComment);
    this.listenTo(this.model, 'destroy', this.remove);
    if (!this.model.get('isSubComment')) {
      this.listenTo(this.model, 'update:subcomments', this.updateSubComments);
    }
  },

  remove: function () {
    var that = this;
    this.$el.fadeOut(function () {
      that.$el.remove();
    });
  },

  updateSubComments: function (content) {
    if (this.subComments) {
      this.subComments.add(content.comment);
    } else {
      this.updateSubCommentCount(content.comment.siblingCount);
    }
  },

  updateSubCommentCount: function (count) {
    var counter = this.$el.find('[data-fn-open-sub-comments]');
    counter.html(
      this.subCommentCountTemplate({
        replyLabel: i18nTimeline('replyLabel'),
        count: count,
      })
    );
  },

  subCommentCountTemplate: _.template('<%- replyLabel  %> (<%- count %>)'),

  openSubComments: function (e) {
    var $subCommentsContainer = this.$el.find('.sv-timeline-sub-comments'),
      $loadingElem = this.$el.find('.sv-comments-loading'),
      focusSubComment =
        e && $(e.currentTarget).is('[data-fn-open-sub-comments]'),
      loadingTimer;

    if (!this.subComments) {
      this.subComments = new CommentsCollection([], {
        id: this.model.id,
        entry: this.options.entryId,
        isSubComment: true,
        portletId: this.options.portletId,
      });

      this.subCommentsView = new CommentsView({
        el: $subCommentsContainer,
        collection: this.subComments,
        portletId: this.options.portletId,
        allowEmojis: this.options.allowEmojis,
        profilePageURL: this.options.profilePageURL,
        tagResultPageURL: this.options.tagResultPageURL,
        maxCharacterCount: this.options.maxCharacterCount,
        requireActiveGroup: this.options.requireActiveGroup,
        userIdentity: this.options.userIdentity,
        getEmojiPicker: this.options.getEmojiPicker,
      });

      this.listenTo(this.subComments, 'add destroy', function () {
        this.updateSubCommentCount(this.subComments.length);
      });

      this.subCommentsView.getSubmitControls().show();
    }

    if ($subCommentsContainer.is(':hidden')) {
      loadingTimer = setTimeout(function () {
        $loadingElem.show();
      }, 400);
      this.subComments.fetch({
        success: function () {
          clearTimeout(loadingTimer);
          $loadingElem.hide();
          $subCommentsContainer.fadeIn('fast', function () {
            if (focusSubComment) {
              $subCommentsContainer.find('textarea').trigger('focus');
            }
          });
        },
      });

      this.$el.find('[data-fn-toggle-sub-comments]').html(
        toggleCommentsTemplate({
          text: i18nTimeline('collapse'),
          direction: 'up',
        })
      );
    } else if (focusSubComment) {
      $subCommentsContainer.find('textarea').trigger('focus');
    }
  },

  toggleSubComments: function (e) {
    var $subCommentsContainer = this.$el.find('.sv-timeline-sub-comments');

    e.preventDefault();
    if ($subCommentsContainer.is(':hidden')) {
      this.openSubComments(e);
    } else {
      $subCommentsContainer.fadeOut();
      this.$el.find('[data-fn-toggle-sub-comments]').html(
        toggleCommentsTemplate({
          text: i18nTimeline('expand'),
          direction: 'down',
        })
      );
    }
  },

  showDestroyDialog: function () {
    var that = this;

    if (!this.options.requireActiveGroup()) {
      return false;
    }

    var isSubComment = this.model.get('isSubComment');
    
    dialogUtil.showConfirmDialog(
      isSubComment ? i18nTimeline('removeReplyTitle') : i18nTimeline('removeCommentTitle'),
      isSubComment ? i18nTimeline('removeReplyBody') : i18nTimeline('removeCommentBody'),
      function (success) {
        if (success) {
          that.model.destroy({
            wait: true,
          });
        }
      }
    );

    return false;
  },

  editComment: function (e) {
    e.preventDefault();

    var author = this.model.get('author');

    if (!this.options.requireActiveGroup()) {
      return false;
    }

    if (
      this.model.collection.isCompoundTimeline &&
      author.id !== this.options.userIdentity
    ) {
      return false;
    }

    ajax
      .doGet({
        context: this,
        url:
          getPortletResourceUri(this.options.portletId, 'plainComment') +
          '&entry=' +
          this.model.collection.id +
          '&commentId=' +
          this.model.get('id'),
      })
      .done(function (response) {
        this.showEditComment(response.plainMessage, response.mentions);
      });

    return false;
  },

  showEditComment: function (message, mentions) {
    var that = this,
      entryId = this.model.collection.id,
      commentId = this.model.get('id'),
      modalContent = $('<div/>');
    const maxCharacterCount = this.options.maxCharacterCount;

    var $charactersLeft = $(
      '<span class="sv-float-right">' +
        (maxCharacterCount - message.length) +
        '</span>'
    );

    var $commentField = $('<textarea/>')
      .attr('id', 'editComment')
      .addClass('sv-comment-input sv-border-box')
      .on(
        'keyup',
        (e) => {
          var commentText = e.currentTarget.value;
          var charactersLeft = maxCharacterCount - commentText.length;
          var $saveEditButton = this.getSaveEditCommentButton();

          if (charactersLeft < 0 || commentText.trim() === '') {
            $charactersLeft.addClass('sv-character-limit-exceeded');
            $saveEditButton
              .addClass('disabled')
              .prop('disabled', true);
          } else {
            $charactersLeft.removeClass('sv-character-limit-exceeded');
            $saveEditButton
              .removeClass('disabled')
              .prop('disabled', false);
          }

          $charactersLeft.text(charactersLeft);
        }
      )
      .val(message);

    modalContent
      .addClass('sv-timeline-portlet')
      .append($commentField)
      .append($charactersLeft);

    if (this.options.allowEmojis) {
      var $emojiButton = $('<span/>')
        .attr({
          role: 'button',
          tabindex: '0',
          'aria-label': i18nTimeline('addEmoji'),
        })
        .addClass('sv-emoji-button')
        .html('&#128515; Emoji');

      modalContent.append($emojiButton);
    }

    var buttons = [
      {
        text: i18nTimeline('save'),
        callback: $.proxy(function () {
          ajax
            .doPut({
              url:
                getPortletResourceUri(that.options.portletId, 'plainComment') +
                '&entry=' +
                entryId +
                '&commentId=' +
                commentId,
              data: {
                comment: $commentField.triggeredInput('getRichContent'),
              },
              context: this,
            })
            .done(function (updatedComment) {
              this.model.set(updatedComment);
            });
        }, this),
      },
    ];

    dialogUtil.showDialog({
      title: this.model.get('isSubComment')
        ? i18nTimeline('editReplyTitle')
        : i18nTimeline('editCommentTitle'),
      body: modalContent,
      buttons: buttons,
      shownCallback: function () {
        $commentField
          .triggeredInput(inputConfig)
          .triggeredInput('setIds', mentions)
          .elastic();

        if (!this.options.allowEmojis) {
          return;
        }

        var picker = this.options.getEmojiPicker('left-end');

        picker.on('emoji', function (emoji) {
          var field = $commentField[0],
            selectionStart = field.selectionStart,
            selectionEnd = field.selectionEnd,
            inputValue = $commentField.val(),
            startString = inputValue.substring(0, selectionStart),
            endString = inputValue.substring(selectionEnd, inputValue.length);

          $commentField.val(startString + emoji + endString);
          $commentField.trigger('focus');
        });

        $emojiButton.on('click', function () {
          picker.pickerVisible
            ? picker.hidePicker()
            : picker.showPicker($emojiButton);
        });
      }.bind(this),
    });

    return false;
  },

  getSaveEditCommentButton: function () {
    return $('.modal-footer button');
  },

  updateComment: function () {
    let isSubCommentsVisible = this.$el.find('.sv-timeline-sub-comments').is(':visible');

    //Re-renders the comment with the updated content (any shown subComments are no longer visible)
    this.render();

    //this.subComments must always be reset when message is changed so they can be re-rendered by this.openSubComments
    this.subComments = null;
    this.subCommentsView = null;

    if (isSubCommentsVisible) {
      //Re-renders the subComments (any previous shown subcomments are visible again)
      this.openSubComments();
    }

    events.trigger(events.types.updateLikables, this.$el);
    events.trigger(events.types.updateRelativeDates, this.$el);
  },

  like: function (e) {
    if (this.options.requireActiveGroup()) {
      return toggleLike.call(e.currentTarget);
    }
  },

  fixLinks: function () {
    var messageContent = this.$el.find('[data-fn-message-content]');

    fixMentionLinks(messageContent, this.options.profilePageURL);
    fixTagLinks(messageContent, this.options.tagResultPageURL);
  },

  render: function () {
    this.$el.html(this.options.template(this.model.toJSON()));
    this.fixLinks();

    this.$el.find('[data-fn-expand-image]').fancybox({
      padding: 5,
      href: $(this).attr('href'),
      helpers: {
        title: {
          type: 'inside',
        },
      },
      transitionIn: 'elastic',
      transitionOut: 'elastic',
    });
    return this;
  },
});

export default Comment;
