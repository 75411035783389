/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 *
 * Handles listing and filtering of a Groups files.
 * @depends portletutil.js, i18n.js, events.js, clientutil.js
 */
import sv from '@sv/core';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import {getPortletResourceUri, getModelObjectUri, getTemplate} from '../../util/portletUtil';
import {
   Events as events,
   ObjectUtil as objectUtil,
   ErrorUtil as errorUtil,
   i18n as _i18n,
} from '@sv/util';

let searchTimer = null,
   i18nPortlet = function(key, args) {
      return _i18n.getText('portlet.social.filelist.filelist', key, args);
   },
   i18nCommon = function(key, args) {
      return _i18n.getText('common', key, args);
   },
   handleError = function(message,heading) {
      events.trigger(events.types.notifyUser, {
         type: 'error',
         heading: heading?heading:i18nCommon('error'),
         message: message
      });
      return false;
   };

// Check if a file that is about to get uploaded will be accepted by the server
var verifyFileUpload = function(aName, aSize, aRepositoryId, aPortletId) {
   var verified = false;

   $.ajax({
      type: 'GET',
      url : getPortletResourceUri(aPortletId, 'verifyUpload'),
      async: false,
      data: {
         repository: aRepositoryId,
         name: aName,
         size: aSize
      },
      success: function(aResponse) {
         verified = aResponse.verified;
      },
      error: function(aResponse) {
         errorUtil.handleAjaxFailure(aResponse);
      }
   });

   return verified;
};

var FileResource = Backbone.Model.extend({

   defaults: {
      name: '',
      iconURL: '/',
      url: '/',
      viewURL: '/'
   },

   getViewUrl: function() {
      return this.get('viewURL');
   },

   url: function() {
      if (this.collection) {
         return this.collection.url() + '&file=' + this.get('id');
      }

      return '?file=' + this.get('id');
   }
});

var FileList = Backbone.Collection.extend({

   model: FileResource,

   initialize: function(models, options) {
      this.id = options.id;
      this.portletId = options.portletId;
      this.groupId = options.groupId;
   },

   url: function() {
      return getPortletResourceUri(this.portletId, 'files') +'&id=' +this.id + (this.groupId ? '&group=' + this.groupId : '');
   }
});

var FileResourceView = Backbone.View.extend({

   tagName: 'li',

   className: 'sv-clearfix sv-file-listitem',

   events: {
      'click [data-fn-destroy]': 'destroy',
      'click': 'navigate'
   },

   initialize: function() {
      this.bindModelEvents();
   },

   bindModelEvents: function() {
      this.model.on('filter', this.toggleVisible, this);
   },

   destroy: function() {

      this.model.destroy({
         wait: true,
         error: function(model, xhr, options) {
            errorUtil.handleBackboneError(model,xhr,options);
         }
      });
      return false;
   },

   navigate: function(e) {
      if (!$(e.target).data('fn-download')) {
         window.location.href = this.model.getViewUrl();
      }
      return;
   },

   toggleVisible: function(opts) {
      this.$el.toggleClass('sv-hidden', !this.isVisible(opts.filter));
   },

   isVisible: function(filter) {
      var file = this.model.get('name');
      return !filter || (file && file.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
   },

   render: function() {
      this.$el.html(this.options.template(this.model.toJSON()));
      return this;
   }

});

var FileListView = Backbone.View.extend({

   events: {
      'keyup [data-fn-filter]'            : 'setFilter',
      'change [data-fn-filter]'           : 'setFilter',
      'blur [data-fn-filter]'             : 'setFilter'
   },

   initialize: function() {
      this.$list = this.options.list;
      this.portletId = this.options.portletId;

      if (this.collection.length > 0) {
         this.render();
      }

      this.bindCollectionEvents();

      var that = this;
      events.on(events.types.groupPageViewed, function() {
         that.$list.find('.sv-new').removeClass('sv-new');
      });
   },

   bindCollectionEvents: function() {
      this.collection.on('add', this.prependOne, this);
   },

   render: function() {
      this.clearList();

      this.collection.each(function(item) {
         this.appendOne(item);
      }, this);

      events.trigger(events.types.updateRelativeDates, this.$list);

   },

   appendOne: function(file) {
      var view = new FileResourceView({
         model: file,
         portletId: this.options.portletId,
         template: this.options.fileTemplate,
         editable: this.options.editable
      });
      this.$list.append(view.render().el);
   },

   prependOne: function(file) {
      var view = new FileResourceView({
         model: file,
         portletId: this.options.portletId,
         template: this.options.fileTemplate,
         editable: this.options.editable
      });
      this.$list.prepend(view.render().el);
      events.trigger(events.types.updateRelativeDates, this.$list);
   },

   clearList: function() {
      this.$list.empty();
   },

   setFilter: function() {
      // cleanup
      if (searchTimer !== null) {
         clearTimeout(searchTimer);
         searchTimer = null;
      }

      searchTimer = window.setTimeout($.proxy(this.fetchCollection, this), 200);
   },

   fetchCollection: function() {
      var term = this.$('[data-fn-filter]').val();
      this.collection.fetch({
         reset: true,
         data: {
            term: term
         }
      }).done($.proxy(function() {
         this.render();
      }, this));
   }
});

$('.sv-fileshare-portlet').each(function() {
   import(/* webpackChunkName: "filelist-plugins" */ './plugins').then(
      () => {
      var $this = $(this),
         portletId = objectUtil.getObjectId($this.attr('id')),
         $list = $this.find('ol'),
         editable = $list.data('editable'),
         templateName = $list.data('template'),
         repositoryId = $list.data('repository'),
         fileRepository = $list.data('file-repository'),
         groupId = $list.data('group'),
         modelObjectUrl = getModelObjectUri(fileRepository, 'fileUpload'),
         fileUploadElem = $this.find('.sv-fn-upload'),
         jsNamespace = $list.data('js-namespace'),
         template = getTemplate($this, templateName),
         progressElem =  $this.find('.sv-fn-upload-progress'),
         progressTimer = null,
         fileList = new FileList(sv[jsNamespace] || [], {
            id: fileRepository,
            portletId: portletId,
            groupId: groupId
         }),
         fileListView = new FileListView({
            el: $this,
            list: $list,
            collection: fileList,
            fileTemplate: template,
            portletId: portletId,
            editable: editable
         });

      events.on(events.types.fileAdded, function() {
         fileListView.fetchCollection.call(fileListView);
      });

      events.on('server-file-added-' +fileRepository, function() {
         fileListView.fetchCollection.call(fileListView);
      });

      fileUploadElem.fileupload({
         dataType: 'json',
         url: modelObjectUrl,  // will do a POST of multi-parts (see FileUploadHandler.uploadParts)
         replaceFileInput: true,
         formAcceptCharset: 'utf-8',
         dropZone: $this,

         // before posting the data
         submit: function(e, data) {
            var file = data.files[0],
               fileSize = file.size;

            // IE8/9 workaround (no size support)
            if (!fileSize) {
               fileSize = 1;
            }

            // Make sure that the file that is about to get uploaded is accepted
            if (e.originalEvent.type === 'paste' || !verifyFileUpload(file.name, fileSize, repositoryId, portletId)) {
               return false;
            }

            progressTimer = setTimeout(function() {
               progressElem.removeClass('svhidden');
            }, 500);
         },

         // post failed (e.g. server not available)
         fail: function() {
            return handleError(i18nPortlet('error-unknown'));
         },

         // after post (failed or successful)
         always: function() {
            if (progressTimer !== null) {
               clearTimeout(progressTimer);
            }

            progressElem.addClass('svhidden');
         }
      });
   });
});